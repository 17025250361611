// 인증메일 검사 페이지

import { useEffect, useState } from 'react'
import Input from '../common/Input'
import * as S from './style'
import useFetch from '../../hooks/useFetch'
import { useLocation, useNavigate } from 'react-router-dom'
import Spinner from '../common/Spinner'
import TokenManager from '../../api/TokenManger'
import useSendRestEmail from '../../hooks/sendRestEmaii'
import useSendInviteEmail from '../../hooks/sendInviteMeail'

export default function MailCode() {
  const [emailAuthCode, setEmailAuthCode] = useState('')
  const [isMail, setIsMail] = useState(false)
  const [email, setEmail] = useState('')
  const navigate = useNavigate()
  const [mailState, setMailState] = useState()
  const { state } = useLocation()
  const { pathname } = useLocation()
  const isReset = pathname === '/mail-code/reset'
  const isDelete = pathname === '/mail-code/delete'

  const { identityId } = state || {}

  useEffect(() => {
    if (!state) navigate('/404')
  }, [])

  const { fetch: sendMailCode, isLoading: isLoadingSendMail } = useFetch({
    url: '/pin/email',
    method: 'post',
    onSuccess: () => {
      navigate('/pin', { state: { ...mailState } })
    },
    onFailure: () => {
      alert('인증 코드가 잘못 되었습니다')
      setIsMail(false)
    },
  })

  const { fetch: resetPin, isLoading: isLoadingResetPin } = useFetch({
    url: '/pin/reset',
    method: 'post',
    onSuccess: ({ type }) => {
      const tokenManager = new TokenManager()
      if (tokenManager.token) tokenManager.deleteToken()

      if (type === 'RECHANGE_PIN_CODE')
        return navigate('/pin', { state: { ...mailState } })
      else return navigate(`/certify?code=${identityId}`)
    },
    onFailure: () => {
      alert('인증 코드가 잘못 되었습니다')
      setIsMail(false)
    },
  })

  const { sendResetEmail, isResetEamilLoading } = useSendRestEmail({
    identityId,
    onSuccess: ({ status }) => {
      setMailState({ identityId, status })
      setIsMail(true)
    },
    onFailure: (e) => {
      if (e.response?.status === 400) {
        alert('해당 메일이 아닙니다')
      }
    },
  })

  const { sendInviteMail, isInviteMailLoading } = useSendInviteEmail({
    identityId,
    onSuccess: ({ status }) => {
      setMailState({ identityId, status })
      setIsMail(true)
    },
    onFailure: (e) => {
      if (e.response?.status === 400) {
        alert('해당 메일이 아닙니다')
      }
    },
  })

  const handleGateWaySendMail = () => {
    if (isReset || isDelete) return sendResetEmail({ identityId, email })
    return sendInviteMail({ identityId, email })
  }

  const handleGateWayMessage = () => {
    if (isReset) return 'PIN 재설정을 위해'
    if (isDelete) return 'PIN 해지를 위해'
    return '본인 확인을 위해'
  }

  const onSubmit = (e) => {
    e.preventDefault()

    if (!isMail) return handleGateWaySendMail()

    if (isReset)
      return resetPin({ emailAuthCode, identityId, type: 'RECHANGE_PIN_CODE' })
    if (isDelete) return resetPin({ emailAuthCode, identityId, type: 'RESET' })
    sendMailCode({ emailAuthCode, identityId })
  }

  return (
    <>
      {isLoadingSendMail || isLoadingResetPin ? (
        <Spinner />
      ) : (
        <S.MailCodeWrapper>
          <S.Form onSubmit={onSubmit}>
            <S.Title>
              {handleGateWayMessage()}
              <br />
              <strong>
                {isMail ? '인증 코드를 입력하세요' : '메일을 입력하세요'}
              </strong>
            </S.Title>
            <S.InputStyle>
              <Input
                id='email'
                type={'email'}
                placeholder={'메일을 입력해주세요'}
                readOnly={isMail}
                setValue={(e) => {
                  setEmail(e.target.value)
                }}
              />
              {isMail && (
                <Input
                  id='title'
                  type={'text'}
                  placeholder={'인증 코드를 입력해주세요'}
                  setValue={(e) => {
                    setEmailAuthCode(e.target.value)
                  }}
                  required
                />
              )}
              <input
                type='submit'
                value={
                  isResetEamilLoading || isInviteMailLoading
                    ? '인증중...'
                    : '인증하기'
                }
              />
            </S.InputStyle>
          </S.Form>
        </S.MailCodeWrapper>
      )}
    </>
  )
}
