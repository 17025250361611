// 온라인 & 오프라인 작품 증명서 페이지

import { useEffect, useState } from 'react'

import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { ArtInfo } from '../Atom/Atom'

import useFetch from '../hooks/useFetch'
import TokenManager from '../api/TokenManger'

import Certify from '../components/Certify'
import Spinner from '../components/common/Spinner'

export default function CertifyPage() {
  const navigate = useNavigate()
  
  // 온라인 or 오프라인 작품 판별을 위한 값입니다.
  const { pathname } = useLocation()

  // 파라미터로 작성된 작품 고유값을 가져오기 위한 로직입니다.
  const [searchParams] = useSearchParams()
  const identityId = encodeURIComponent(searchParams.get('code'))
  
  const [artInfo, setArtInfo] = useRecoilState(ArtInfo)
  const [isExistPin, setIsExistPin] = useState()

  useEffect(() => {
    checkPinExistFetch()
  }, [])

  const handleGetDetail = () => {
    if (pathname === '/certify/of') getOfDetailFetch()
    else getDetailFetch()
  }

  // 해당 작품이 핀번호가 존재하는지 검사하는 요청
  const { fetch: checkPinExistFetch } = useFetch({
    url: `/pin/exist?identityId=${identityId}`,
    method: 'get',
    onSuccess: ({ pinCodeExist }) => {
      if (pinCodeExist) {
        checkPinStatusFetch()
      } else {
        handleGetDetail()
      }
      setIsExistPin(pinCodeExist)
    },
    onFailure: () => navigate('/404'),
  })

  // 현재 로컬스토리지에 있는 핀의 상태를 검사하는 요청
  const { fetch: checkPinStatusFetch, isLoading } = useFetch({
    url: `/pin?identityId=${identityId}`,
    method: 'get',
    onSuccess: ({ status }) => {
      switch (status) {
        case '-4000':
          const tokenManager = new TokenManager()
          if (tokenManager.token) tokenManager.deleteToken()
          return navigate('/pin', { state: { identityId, status } })
        case '2000':
          return handleGetDetail()
        default:
          break
      }
    },
    onFailure: (e) => {
      if (e.response.status === 401) return checkPinExistFetch()
      navigate('/404')
    },
  })

  // 온라인 작품 증명서 정보를 가져오는 요청
  const { fetch: getDetailFetch } = useFetch({
    url: `/nft/detail?identityId=${identityId}`,
    method: 'get',
    onSuccess: setArtInfo,
    onFailure: () => navigate('/404'),
  })

  // 오프라인 작품 증명서 정보를 가져오는 요청
  const { fetch: getOfDetailFetch } = useFetch({
    url: `/nft/detail/of?identityId=${identityId}`,
    method: 'get',
    onSuccess: setArtInfo,
    onFailure: () => navigate('/404'),
  })

  return isLoading || !artInfo ? (
    <Spinner />
  ) : (
    <Certify isExistPin={isExistPin} identityId={identityId} />
  )
}
