// 온라인 & 오프라인 작품 증명서 페이지

import { useState } from 'react'

import { useNavigate } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { ArtInfo } from '../../Atom/Atom'

import InfoTab from '../common/InfoTab'
import Tabs from '../common/Tabs'
import Portal from '../common/Portal'
import Alert from '../common/Alert'

import Slider from '../Slider'
import Canvas from '../Canvas'

import * as S from './style'

export default function Certify({ isExistPin, identityId }) {
  const [modalToggle, setModalToggle] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  
  const artInfo = useRecoilValue(ArtInfo)
  const navigate = useNavigate()
  const handleCloseAlert = () => setAlertMessage('')

  const handleSetAlertMessage = (mode) => {
    if (mode) setAlertMessage('정말로 PIN 해지를 하시겠습니까?')
    else setAlertMessage('정말로 PIN 등록을 하시겠습니까?')
  }

  return (
    <>
      <S.Layer>
        <S.PinSettingBtn>
          {artInfo.isExistBuyCustomer && (
            <button onClick={() => handleSetAlertMessage(isExistPin)}>
              {isExistPin ? 'PIN 해지' : 'PIN 등록'}
            </button>
          )}
        </S.PinSettingBtn>
        <S.ProductWrapper>
          <S.LogoWrapper>
            <div>
              <img src={process.env.PUBLIC_URL + '/logo_white.png'} alt='logo' />
            </div>
          </S.LogoWrapper>
          <S.ProductImgWrapper>
            {artInfo.imageUrl && <Canvas artBuffer={artInfo.imageUrl} />}
          </S.ProductImgWrapper>
        </S.ProductWrapper>
        <Tabs />
        <InfoTab
          moreComponent={{
            artwork: (
              <S.Footer>
                <S.LogoWrapper>
                  <div>
                    <img
                      src={process.env.PUBLIC_URL + '/logo_white.png'}
                      alt='logo'
                    />
                  </div>
                </S.LogoWrapper>
              </S.Footer>
            ),
          }}
        />
      </S.Layer>
      {artInfo.photoCardImgUrl && (
        <S.PhotoToggleButton onClick={() => setModalToggle((prev) => !prev)}>
          포토카드 {modalToggle ? '닫기' : '확인'}
        </S.PhotoToggleButton>
      )}
      {modalToggle && (
        <Portal onClose={() => setModalToggle((prev) => !prev)}>
          <Slider slideList={artInfo.photoCardImgUrl} />
        </Portal>
      )}
      {alertMessage && (
        <Portal onClose={handleCloseAlert}>
          <Alert
            onCheck={
              isExistPin
                ? () => navigate('/mail-code/delete', { state: { identityId } })
                : () => navigate('/mail-code', { state: { identityId } })
            }
            onClose={handleCloseAlert}
            alertMessage={alertMessage}
          />
        </Portal>
      )}
    </>
  )
}
