import { isAxiosError } from 'axios'
import { useState, useCallback } from 'react'
import API from '../api'

const useFetch = ({
  url,
  method,
  onSuccess,
  onFailure,
  onFinaly,
  successMessage,
  errorMessage,
}) => {
  const [data, setData] = useState(null)
  const [isLoading, setLoading] = useState(false)

  const fetch = useCallback(
    async (body) => {
      setLoading(true)

      try {
        const { data } = await API({
          url: url,
          method: method,
          data: body,
        })
        setData(data)

        if (successMessage) console.log('성공')
        if (onSuccess) await onSuccess(data)
      } catch (e) {
        if (!isAxiosError(e)) return console.log('알수 없는 에어')
        else if (e.response && e.response.status >= 500)
          console.log('알수 없는 에어')
        else if (errorMessage && e.response && errorMessage[e.response.status])
          await console.log(errorMessage[e.response.status])

        if (onFailure) await onFailure(e)
      } finally {
        if (onFinaly) onFinaly()
        setLoading(false)
      }
    },
    [url, method, onSuccess, onFailure, successMessage, errorMessage],
  )

  return { data, isLoading, fetch }
}

export default useFetch


